.middle-section {
    width: calc(100% - 270px);
    max-width: calc(100% - 270px);
    // margin-left: 270px;
    position: absolute;
    right: 0;
    transition: all 200ms ease-in-out;

    &.both-sports{
      width: calc(100% - 365px);
    }

    .middle-section-top-align {
      padding-bottom: 30px;
    }

    @media screen and (max-width: 1199px) {
      width: calc(100% - 100px);
      max-width: calc(100% - 100px);
      margin-left: 100px;
    }

    @media screen and (max-width: 767px) {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
    }
  }
