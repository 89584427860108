@import '../components/mixin';

.score-boar-modal {

  .modal-body {
    position: relative;

    .bg-bet {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .main-detail {
      position: relative;
      z-index: 2;
      width: 100%;
      background: var(--bgSupportTertiary);
      border-radius: 3px;

      .top-result {
        width: 100%;
        background: radial-gradient(59.48% 78.95% at 51.89% 0%, rgba(255, 212, 60, .4) .01%, rgba(255, 217, 120, 0) 100%);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        border-radius: inherit;
        padding: 0.75rem;

        .top-profits {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          .profit-detail {
            margin-top: 0 !important;
            font-size: 1.75rem;
            line-height: 1.75rem;
            font-weight: 800;
          }
        }

        .bet-payout {
          border-radius: 6px;
          padding: 10px 6px;
          background: var(--bgSupportSecondary);
        }
      }

      .mid-info {
        .top-line {
          position: relative;

          .ball-l,
          .ball-r {
            background: var(--bgModal);
            width: 20px;
            height: 20px;
            border-radius: 50px;
            position: absolute;

            z-index: 10;
            top: 5px;
            transform: translateY(-50%);
          }

          .ball-l {
            left: -10px;
          }

          .ball-r {
            right: -10px;
          }

          .l {
            transform: scaleY(.25);
            border: 4px dashed var(--borderColor);
          }
        }
      }

      .bottom-info {
        background: radial-gradient(59.48% 78.95% at 51.89% 0%, rgba(255, 217, 120, 0) 100%);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        border-radius: inherit;
        padding: 0.75rem;

        .info {
          border-radius: 6px;
          padding: 10px 6px;
          background: var(--bgSupportSecondary);
          @include text-sm;
        }
      }

    }
  }
}

.modal-content {
  border-radius: var(--borderRadius);
  backdrop-filter: blur(23px);
  padding: 1rem;
  border: 1px solid var(--borderColor);

  .modal-header {
    padding-bottom: 1rem !important;

    .btn-close {
      opacity: 1;
      color: var(--bodyText);
      filter: invert(1);
      background-size: 0.8rem;
      padding-right: 24px;

      &:hover,
      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .modal-header,
  .modal-footer {
    border: 0;
    padding: 0;
  }

  .modal-body {
    padding: 0;

    &.login-modal {
      padding: 1rem;

      @include mobile {
        padding: 0;
      }

    }

    &.auth-wrapper {
      .sign-up-wrapper {
        display: flex;
        gap: 40px;
        height: 500px;
        @include tablet{
          height: 780px;
        }

        @media screen and (orientation:landscape) and (max-height:700px) {
          height: 500px;

        }

        @media screen and (orientation:landscape) and (max-height:800px) {
          height: 500px;

        }

        @media screen and (orientation:landscape) and (max-height:900px) {
          height: 600px;

        }

        @media screen and (orientation:landscape) and (max-height:1080px) {
          height: 827px;

        }

        @media screen and (orientation:landscape) and (min-height:1080px) {
          height: 827px;

        }

        @media screen and (orientation: portrait) and (max-height:900px) {
          height: 780px;

        }
        @media screen and (orientation: portrait) and (max-height:800px) {
          height: 680px;

        }
        @media screen and (orientation: portrait) and (max-height:700px) {
          height: 580px;

        }

        @include upToLg {
          flex-direction: column;
        }



      }
      &.login-modal{
        .form-wrapper{
          padding-top: 0;
        }
      }
             .left-wrapper,
             .form-wrapper {
               flex: 1;
               height: 100%;
             }

             h2 {
               margin-bottom: 2px;
               font-size: 24px !important;
             }

             .para {
               color: var(--gray);
             }

             .form-wrapper {
               display: flex;
               flex-direction: column;



               .signup-section1 {
                 flex-grow: 1;

                 .signup-form1 {
                   height: 100%;

                   .scrollable-content {
                     height: 100%;
                     overflow-y: auto;
                     padding-right: 10px;
                     max-height: 400px;
                     @include mobile{
                      max-height: unset;
                     }

                   }
                 }
               }




               .sticky-footer {
                 background: var(--bgModal);
                 z-index: 10000;
                 padding-top: 10px;
               }
             }

             .left-wrapper {
               overflow: hidden;
               border-radius: 16px;
               height: calc(100% - 42px);
               display: flex;
               flex-direction: column;

               @include upToLg {
                 display: none;
               }

               .img-wrapper {
                 border-radius: 16px;
                 overflow: hidden;
                 margin-top: 8px;
                 flex-grow: 1;

                 img {
                   width: 100%;
                   border-radius: 16px;
                   height: 100%;
                   object-fit: cover;
                   object-position: bottom;

                 }
               }
             }
    }
  }

  .modal-title {
    font-size: 1.125em;
    color: var(--bodyText);
    font-weight: 700;
  }


  .submit-btn {
    @include SecondaryBtn();
    box-shadow: none;


  }

  .forget-pwd {
    font-weight: var(--fontWeight400);

    &:hover {
      text-decoration: underline !important;
    }
  }

  .dont-have--acc {
    font-weight: var(--fontWeight400);

    p {
      color: var(--gray);
      font-size: 12px !important;

    }

    a {
      color: var(--textWhite);
      transition: all 0.3s ease-in-out;

      &:hover {
        text-decoration: underline !important;
      }
    }
  }

  .pwd-icon {
    position: absolute;
    top: 30px;
    right: 20px;
    cursor: pointer;
  }
}

.transparent-modal {
  backdrop-filter: blur(8px);

  .modal-content {
    background-color: transparent !important;
    backdrop-filter: none;
  }
}

.entry-modal {
  .entry-image-wrapper {
    display: flex;
    justify-content: end;

    .loading-image,
    img {
      width: 100%;
      aspect-ratio: 4/5;
      max-width: 450px;
      max-height: 600px;

      @include mobile {
        width: 100%;
      }
    }
  }

  .close-btn-wrapper {
    img {
      filter: none !important;
    }
  }

  .close-btn-wrapper {
    img {
      filter: none !important;
    }
  }
}

.popup-images-modal {
  .popup-images-wrapper {
    &.less-than-four {
      justify-content: flex-start;
    }

    display: flex;
    flex-wrap: wrap-reverse;
    justify-content: end;

    .popup-image-wrapper {
      .close-image-btn-wrapper {
        cursor: pointer;
        width: 35px;
        height: 35px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: auto;

      }

      width: calc(100%/4);
      aspect-ratio: 4/5;

      @include tablet {
        width: calc(100%/3);
      }

      @include mobile {
        width: calc(100%/2);
      }

      .loading-image,
      img {
        width: 100%;
        aspect-ratio: 4/5;
      }
    }
  }
}

.season-modal {
  .modal-content {
    border-radius: 24px;
    position: relative;
    width: 550px;
    height: 500px;

    @include mobile {
      height: 430px;
    }

    .bg-img-new-season {
      position: absolute;
      inset: 0;
      z-index: 9;
    }

    .title {
      position: absolute;
      top: 0px;
      left: 16px;
      z-index: 11;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: flex-start;

      span {
        line-height: 1;
        display: block;
        font-size: 35px;
        font-weight: 600;

        @include mobile {
          font-size: 30px;
        }

        text-transform: uppercase;

        &.unlock {
          color: var(--primary);
        }

        &.season-date {
          font-size: 20px;
          font-weight: 500;
          border: 1px solid var(--primary);
          padding: 6px;
          letter-spacing: 2px;
          margin-top: 2px;
        }
      }
    }
  }
}

.gift-card-progress-modal {
  .progress-content {
    padding: 40px;

    .overlay-text-custom {
      opacity: 1 !important;
    }
  }
}
.session-logout-success{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  min-height: 300px;
.timer-icon{
  font-size: 100px;
  line-height: 1;
}
}
