@import '../../scss/components/mixin';
.deposit-withdraw {
  .amount-tabs {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;

    .amount-tab-btn {
      padding: 8px 12px;
      border-radius: 6px;
      font-size: 14px;
      background-color: var(--bgSearchField);
      flex-grow: 1;
      text-align: center;
      font-weight: 500;
      cursor: pointer;

      @include mobile {
        font-size: 13px;
      }

      &.active {
        background-color: var(--primary);
      }

      &.disabled {
        pointer-events: none;
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .locked-fund {
    text-decoration: underline;
  }
}
